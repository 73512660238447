import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Meta } from '../explorer';
import { Client, ClientList, ClientRequest } from './requests';

export default createSlice({
  name: 'clientOIDC',
  initialState: {
    loading: false,
    error: '' as string,
    client_id: '' as string,
    secret: '' as string,
    clients: [] as Client[],
    pagination: {} as Meta,
    clientKeys: {} as ClientRequest
  },
  reducers: {
    createClient: (
      state,
      action: PayloadAction<{
        name: string;
        redirect_uri: string;
        logout_redirect_uri: string;
        image?: string;
        user_id?: string;
      }>
    ) => {
      state.loading = true;
    },
    onCreateClientSuccess: (state, action: PayloadAction<ClientRequest>) => {
      state.client_id = action.payload.client_id;
      state.secret = action.payload.secret;
      state.loading = false;
    },
    onCreateClientError: (state, action: PayloadAction<{ error: number }>) => {
      switch (action.payload.error) {
        default:
          state.error = 'Error while creating client';
      }
    },
    getClientList: (state, action: PayloadAction<{ page?: number; pageSize?: number }>) => {
      state.loading = true;
    },
    onGetClientsListSuccess: (state, action: PayloadAction<ClientList>) => {
      state.clients = action.payload.clients && action.payload.clients;
      state.pagination = action.payload.pagination && action.payload.pagination.meta;
      state.loading = false;
    },
    onGetClientsListError: (state) => {
      state.loading = false;
    }
  }
});
