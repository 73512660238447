import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAddTOTP } from '../../components/modal/add-access-control-totp';
import { IChangePasswordModal } from '../../components/modal/change-password';
import { ICreateApiKeyModal } from '../../components/modal/create-api-key';
import { IInfoModal } from '../../components/modal/info';
import { IResetAccessControlPassowrd } from '../../components/modal/reset-access-control-password';
import { IFeedbackModal } from '../../components/modal/send-feedback';
import { IAdvertiseModal } from '../../components/modal/update-plan-advertise';

export enum ModalType {
  CREATE_API_KEY_MODAL = 'CREATE_API_KEY_MODAL',
  INFO_MODAL = 'INFO_MODAL',
  FEEDBACK_MODAL = 'FEEDBACK_MODAL',
  CHANGE_PASSWORD_MODAL = 'CHANGE_PASSWORD_MODAL',
  ADVERTISE_UPDATE_PLAN = 'ADVERTISE_UPDATE_PLAN',
  CREATE_WEBHOOK = 'CREATE_WEBHOOK',
  CREATE_ACCESS_CONTROL_TOTP = 'CREATE_ACCESS_CONTROL_TOTP',
  RESET_ACCESS_CONTROL_PASSWORD = 'RESET_ACCESS_CONTROL_PASSWORD',
  VERIFY_ACCESS_CONTROL = 'VERIFY_ACCESS_CONTROL',
  OIDC_CLIENT_MODAL = 'OIDC_CLIENT_MODAL'
}

export type ModalState = {
  type: ModalType;
  props: any;
};

export default createSlice({
  name: 'modal',
  initialState: {
    active: undefined as ModalState | undefined
  },
  reducers: {
    openCreateApiKeyModal: (state, action: PayloadAction<ICreateApiKeyModal>) => {
      state.active = {
        type: ModalType.CREATE_API_KEY_MODAL,
        props: action.payload
      };
    },

    openInfoModal: (state, action: PayloadAction<IInfoModal>) => {
      state.active = {
        type: ModalType.INFO_MODAL,
        props: action.payload
      };
    },

    openFeedbackModal: (state, action: PayloadAction<IFeedbackModal>) => {
      state.active = {
        type: ModalType.FEEDBACK_MODAL,
        props: action.payload
      };
    },

    openChangePasswordModal: (state, action: PayloadAction<IChangePasswordModal>) => {
      state.active = {
        type: ModalType.CHANGE_PASSWORD_MODAL,
        props: action.payload
      };
    },

    openAdvertiseUpdatePlanModal: (state, action: PayloadAction<IAdvertiseModal>) => {
      state.active = {
        type: ModalType.ADVERTISE_UPDATE_PLAN,
        props: action.payload
      };
    },

    openAddTOTPModal: (state, action: PayloadAction<IAddTOTP>) => {
      state.active = {
        type: ModalType.CREATE_ACCESS_CONTROL_TOTP,
        props: action.payload
      };
    },

    resetAccessControlPassword: (state, action: PayloadAction<IResetAccessControlPassowrd>) => {
      state.active = {
        type: ModalType.RESET_ACCESS_CONTROL_PASSWORD,
        props: action.payload
      };
    },

    openVerifyAccessControl: (state, action: PayloadAction<any>) => {
      state.active = {
        type: ModalType.VERIFY_ACCESS_CONTROL,
        props: action.payload
      };
    },

    openCreateOidcClient: (state, action: PayloadAction<any>) => {
      state.active = {
        type: ModalType.OIDC_CLIENT_MODAL,
        props: action.payload
      };
    },

    close: (state) => {
      delete state.active;
    }
  }
});
