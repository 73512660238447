import { BloockClient } from '@bloock/sdk';
import { HttpClient } from '../../http/client';
import { camelize } from '../../utils/camelize';
import { isNumber } from '../../utils/is-number';
import { snakeCase } from '../../utils/snake_case';
import { Meta } from '../explorer';

export type SchemaAttribute = {
  id?: string;
  name: string;
  type: 'boolean' | 'string' | 'integer' | 'decimal' | 'number' | 'date' | 'date-time';
  format?: 'date' | 'date-time';
  description?: string;
  required: boolean;
};

export type Schema = {
  name: string;
  description?: string;
  fields: { [key: string]: SchemaAttribute };
};

export type CreateSchemaRespone = {
  cid: string;
};

export async function requestCreateSchema(
  schema: Schema,
  token: string
): Promise<CreateSchemaRespone> {
  const bloockClient = new BloockClient(token, '');
  const schemaBuilder = bloockClient.IdentityClient.buildSchema(
    schema.name,
    camelize(schema.name),
    '1.0',
    schema.description || ''
  );

  Object.keys(schema.fields).forEach((id) => {
    const attribute = schema.fields[id];
    switch (attribute.type) {
      case 'boolean':
        schemaBuilder.addBooleanAttribute(
          attribute.name,
          snakeCase(attribute.name),
          attribute.description || '',
          attribute.required
        );
        break;
      case 'integer':
        schemaBuilder.addIntegerAttribute(
          attribute.name,
          snakeCase(attribute.name),
          attribute.description || '',
          attribute.required
        );
        break;
      case 'decimal':
        schemaBuilder.addDecimalAttribute(
          attribute.name,
          snakeCase(attribute.name),
          attribute.description || '',
          attribute.required
        );
        break;
      case 'string':
        schemaBuilder.addStringAttribute(
          attribute.name,
          snakeCase(attribute.name),
          attribute.description || '',
          attribute.required
        );
        break;
      case 'date':
        schemaBuilder.addDateAttribute(
          attribute.name,
          snakeCase(attribute.name),
          attribute.description || '',
          attribute.required
        );
        break;
      case 'date-time':
        schemaBuilder.addDateTimeAttribute(
          attribute.name,
          snakeCase(attribute.name),
          attribute.description || '',
          attribute.required
        );
        break;
    }
  });

  return await schemaBuilder.build();
}

export type CredentialSchema = {
  schema_json_cid: string;
  schema_json_ld_cid?: string;
  issuer_did: string;
  type: string;
  hash?: string;
  url_json: string;
  url_json_ld?: string;
  created_at: string;
};

export type CredentialSchemasListResponse = {
  schemas: CredentialSchema[];
  pagination: {
    meta: Meta;
  };
};

export async function requestGetCredentialSchemasList(
  page: number,
  pageSize: number
): Promise<CredentialSchemasListResponse> {
  const url = `/identityV2/v1/schemas?page=${page}&per_page=${pageSize}`;
  return await HttpClient.get(url);
}

export type CredentialSchemasDetail = {
  schema_json_cid: string;
  schema_json_ld_cid: string;
  issuer_did: string;
  type: string;
  description?: string;
  hash: string;
  url_json: string;
  url_json_ld: string;
  created_at: string;
};
export async function requestGetCredentialSchemasDetail(
  schema_json_cid: string
): Promise<CredentialSchemasDetail> {
  const url = `/identityV2/v1/schemas/${schema_json_cid}`;
  return await HttpClient.get(url);
}

export async function requestDeleteCredentialSchema(cid: string): Promise<void> {
  const url = `/identityV2/v1/schemas/${cid}`;
  return await HttpClient.delete(url);
}

export type CreateCredential = {
  schema: string;
  holder: string;
  issuer: string;
  expiration: number;
  attributes: { [key: string]: any };
};

type CreateCredentialResponse = {
  id: string;
};

export async function requestTestSchema(credential: CreateCredential): Promise<string> {
  const url = `/v1/credentials?issuer_key=${credential.issuer}`;
  const body = {
    schema_id: credential.schema,
    holder_did: credential.holder,
    credential_subject: Object.keys(credential.attributes).map((key) => {
      let value = credential.attributes[key];
      if (value === 'true' || value === 'false') {
        value = value === 'true';
      } else if (isNumber(value)) {
        if (value.includes(',') || value.includes('.')) {
          value = parseFloat(value);
        } else {
          value = parseInt(value);
        }
      }
      return {
        key,
        value
      };
    }),
    expiration: credential.expiration,

    version: 0
  };

  const response: CreateCredentialResponse = await HttpClient.post(url, body, {
    host: process.env.REACT_APP_IDENTITY_MANAGED_API
  });

  const offeringUrl = `/v1/credentials/${response.id}/offer?issuer_key=${credential.issuer}`;
  const offeringResponse: any = await HttpClient.get(offeringUrl, {
    host: process.env.REACT_APP_IDENTITY_MANAGED_API
  });

  return JSON.stringify(offeringResponse);
}
