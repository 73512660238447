import accessControls from './access-control';
import anchors from './anchors';
import { default as apiKeyDelete, default as apiKeys } from './apikeys';
import auditTrail from './audit-trail';
import auth from './auth';
import availability from './availability';
import certificates from './certificates';
import certifier from './certifier';
import changePassword from './change-password';
import configuration from './configuration';
import credentialSchemas from './credential-schemas';
import dashboard from './dashboard';
import explorer from './explorer';
import feedback from './feedback';
import forgotPassword from './forgot-password';
import invoices from './invoices';
import issuers from './issuers';
import keyOperations from './key-operations';
import keys from './keys';
import licenses from './licenses';
import login from './login';
import logs from './logs';
import metadata from './metadata';
import modal from './modal';
import clientOIDC from './oidc-client';
import payment from './payment';
import profile from './profile';
import records from './records';
import resetPassword from './reset-password';
import signup from './signup';
import statistics from './statistics';
import usage from './usage';
import userProduct from './user-product';
import validation from './validation';
import validationQueries from './validation-queries';
import verifyAccount from './verify-account';
import webhooks from './webhooks';

export const authActions = auth.actions;
export const loginActions = login.actions;
export const signupActions = signup.actions;
export const dashboardActions = dashboard.actions;
export const changePasswordActions = changePassword.actions;
export const profileActions = profile.actions;
export const resetPasswordActions = resetPassword.actions;
export const forgotPasswordActions = forgotPassword.actions;
export const apiKeysActions = apiKeys.actions;
export const apiKeyDeleteActions = apiKeyDelete.actions;
export const explorerActions = explorer.actions;
export const modalActions = modal.actions;
export const statisticsActions = statistics.actions;
export const verifyAccountActions = verifyAccount.actions;
export const metadataActions = metadata.actions;
export const feedbackActions = feedback.actions;
export const configurationActions = configuration.actions;
export const userProductActions = userProduct.actions;
export const paymentActions = payment.actions;
export const logsActions = logs.actions;
export const webhookActions = webhooks.actions;
export const licensesActions = licenses.actions;
export const certifierActions = certifier.actions;
export const validationActions = validation.actions;
export const invoicesActions = invoices.actions;
export const usageActions = usage.actions;
export const availabilityActions = availability.actions;
export const keysActions = keys.actions;
export const anchorsActions = anchors.actions;
export const recordsActions = records.actions;
export const certificatesActions = certificates.actions;
export const keyOperationsActions = keyOperations.actions;
export const identityIssuerActions = issuers.actions;
export const identityValidationQueriesActions = validationQueries.actions;
export const identityCredentialSchemasActions = credentialSchemas.actions;
export const identityAuditTrailActions = auditTrail.actions;
export const accessControlActions = accessControls.actions;
export const clientOIDCActions = clientOIDC.actions;
