import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { clientOIDCActions } from '../actions';
import { getAuthUserId } from '../auth';
import { ClientList, ClientRequest, requestCreateClient, requestGetClientList } from './requests';

function* getClientsList(action: PayloadAction<{ page: number; pageSize: number }>) {
  try {
    const userId: string = yield select(getAuthUserId);

    const response: ClientList = yield call(
      requestGetClientList,
      action.payload.page ? action.payload.page : 1,
      action.payload.pageSize ? action.payload.pageSize : 10,
      userId
    );
    yield put(clientOIDCActions.onGetClientsListSuccess(response));
  } catch (e) {
    console.log(e);
  }
}

function* createClient(
  action: PayloadAction<{
    name: string;
    redirect_uri: string;
    logout_redirect_uri: string;
    image?: string;
    user_id?: string;
  }>
) {
  try {
    const response: ClientRequest = yield call(requestCreateClient, action.payload);
    yield put(clientOIDCActions.onCreateClientSuccess(response));
  } catch (e: any) {
    yield put(clientOIDCActions.onCreateClientError({ error: e }));
  }
}

export default function* ClientOIDCSaga() {
  yield takeLatest(clientOIDCActions.getClientList.type, getClientsList);
  yield takeLatest(clientOIDCActions.createClient.type, createClient);
}
