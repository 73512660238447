import { Reducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { RouterState } from 'redux-first-history';
import accessControl from './access-control';
import anchors from './anchors';
import apiKeys from './apikeys';
import auditTrail from './audit-trail';
import auth from './auth';
import availability from './availability';
import certificates from './certificates';
import certifier from './certifier';
import changePassword from './change-password';
import configuration from './configuration';
import credentialSchemas from './credential-schemas';
import dashboard from './dashboard';
import explorer from './explorer';
import feedback from './feedback';
import forgotPassword from './forgot-password';
import invoices from './invoices';
import issuers from './issuers';
import keyOperations from './key-operations';
import keys from './keys';
import licenses from './licenses';
import login from './login';
import logs from './logs';
import metadata from './metadata';
import modal from './modal';
import clientOIDC from './oidc-client';
import payment from './payment';
import profile from './profile';
import records from './records';
import resetPassword from './reset-password';
import signup from './signup';
import statistics from './statistics';
import usage from './usage';
import userProduct from './user-product';
import validation from './validation';
import validationQueries from './validation-queries';
import verifyAccount from './verify-account';
import webhooks from './webhooks';

const createRootReducer = (routerReducer: Reducer<RouterState>) =>
  combineReducers({
    router: routerReducer,
    auth: auth.reducer,
    dashboard: dashboard.reducer,
    login: login.reducer,
    signup: signup.reducer,
    profile: profile.reducer,
    changePassword: changePassword.reducer,
    resetPassword: resetPassword.reducer,
    forgotPassword: forgotPassword.reducer,
    apiKeys: apiKeys.reducer,
    explorer: explorer.reducer,
    modal: modal.reducer,
    statistics: statistics.reducer,
    verifyAccount: verifyAccount.reducer,
    metadata: metadata.reducer,
    feedback: feedback.reducer,
    configuration: configuration.reducer,
    userProduct: userProduct.reducer,
    payment: payment.reducer,
    logs: logs.reducer,
    webhooks: webhooks.reducer,
    licenses: licenses.reducer,
    certifier: certifier.reducer,
    validation: validation.reducer,
    invoices: invoices.reducer,
    usage: usage.reducer,
    issuers: issuers.reducer,
    validationQueries: validationQueries.reducer,
    credentialSchemas: credentialSchemas.reducer,
    auditTrail: auditTrail.reducer,
    availability: availability.reducer,
    keys: keys.reducer,
    anchors: anchors.reducer,
    records: records.reducer,
    certificates: certificates.reducer,
    keyOperations: keyOperations.reducer,
    accessControl: accessControl.reducer,
    clientOIDC: clientOIDC.reducer
  });

export type AppState = ReturnType<ReturnType<typeof createRootReducer>>;

export default createRootReducer;
