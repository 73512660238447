import { ParsedUrlQuery } from 'querystring';
import React from 'react';
import { Actions, Resource } from '../auth/scopes';
import { CheckScope, RedirectIfAuthenticated } from '../auth/withAuth';

const AnchorDetail = React.lazy(() => import('../pages/products/integrity/detail'));
const RecordDetail = React.lazy(() => import('../pages/products/integrity/detail-record'));
const Configuration = React.lazy(() => import('../pages/configuration'));
const Apikeys = React.lazy(() => import('../pages/developers/apikeys'));
const CreateApiKey = React.lazy(() => import('../pages/developers/apikeys/create-apikey'));
const Logs = React.lazy(() => import('../pages/developers/logs'));
const Documentation = React.lazy(() => import('../pages/developers/documentation'));
const ForgotPassword = React.lazy(() => import('../pages/forgot-password'));
const Home = React.lazy(() => import('../pages/home'));
const Login = React.lazy(() => import('../pages/login'));
const Payment = React.lazy(() => import('../pages/payment'));
const PaymentSuccess = React.lazy(() => import('../pages/payment/success'));
const Profile = React.lazy(() => import('../pages/profile'));
const ResetPassword = React.lazy(() => import('../pages/reset-password'));
const Signup = React.lazy(() => import('../pages/signup'));
const Webhooks = React.lazy(() => import('../pages/developers/webhooks'));
const WebhooksDetail = React.lazy(() => import('../pages/developers/webhooks/detail'));
const Licenses = React.lazy(() => import('../pages/licenses'));
const VerifyAccount = React.lazy(() => import('../pages/verify-account'));
const Certifier = React.lazy(() => import('../pages/certifier'));
const DetailCertifier = React.lazy(() => import('../pages/certifier-detail'));
const ValidationWeb = React.lazy(() => import('../pages/validation'));
const Billing = React.lazy(() => import('../pages/billing'));
const Metrics = React.lazy(() => import('../pages/billing/metrics'));
const PlansAndUsage = React.lazy(() => import('../pages/billing/plans-and-usage'));
const Invoices = React.lazy(() => import('../pages/billing/invoices'));
const UpdatePlan = React.lazy(() => import('../pages/billing/plans-and-usage/update-plan'));
const Integrity = React.lazy(() => import('../pages/products/integrity'));
const Identity = React.lazy(() => import('../pages/products/identity'));
const IssuerCreate = React.lazy(() => import('../pages/products/identity/create-issuer'));
const IssuerDetails = React.lazy(() => import('../pages/products/identity/issuer-details'));
const SchemaDetails = React.lazy(() => import('../pages/products/identity/schema-details'));
const ImportSchema = React.lazy(() => import('../pages/products/identity/import-schema'));
const CreateSchema = React.lazy(() => import('../pages/products/identity/create-schema'));
const CreateQuery = React.lazy(() => import('../pages/products/identity/create-query'));
const Availability = React.lazy(() => import('../pages/products/availability'));
const HostedUploadFile = React.lazy(
  () => import('../pages/products/availability/hosted-upload-file')
);
const IpfsUploadFile = React.lazy(() => import('../pages/products/availability/ipfs-upload-file'));
const IpfsUploadDirectory = React.lazy(
  () => import('../pages/products/availability/ipfs-upload-directory')
);
const Keys = React.lazy(() => import('../pages/products/keys'));
const CreateKey = React.lazy(() => import('../pages/products/keys/create-key'));
const CreateCertificate = React.lazy(() => import('../pages/products/keys/create-certificate'));
const ImportCertificate = React.lazy(() => import('../pages/products/keys/import-certificate'));
const KeyDetail = React.lazy(() => import('../pages/products/keys/detail-key'));
const CertificateDetail = React.lazy(() => import('../pages/products/keys/detail-certificate'));
const WebhooksNewCreate = React.lazy(
  () => import('../pages/developers/webhooks/create-new-webhooks')
);
const CreateOIDCClient = React.lazy(() => import('../pages/products/identity/create-OIDC-client'));

export type Route = {
  label?: string;
  url: string;
  render: () => React.FC | JSX.Element;
  enabled: boolean;
};

export const routes: { [key: string]: Route } = {
  login: {
    label: 'login',
    url: '/login',
    render: () => (
      <RedirectIfAuthenticated>
        <Login />
      </RedirectIfAuthenticated>
    ),
    enabled: true
  },
  home: {
    url: '/home',
    render: () => (
      <CheckScope
        scopes={{ [Resource.UsersUser]: [Actions.Read] }}
        redirectIfNotAllowed={routes.login}
      >
        <Home />
      </CheckScope>
    ),
    enabled: true
  },
  payment: {
    label: 'payment',
    url: '/payment',
    render: () => (
      <CheckScope
        scopes={{
          [Resource.UsersUser]: [Actions.Read],
          [Resource.SubscriptionsSubscription]: [Actions.Create]
        }}
        redirectIfNotAllowed={routes.login}
      >
        <Payment />
      </CheckScope>
    ),
    enabled: true
  },
  paymentSuccess: {
    label: 'paymentSuccess',
    url: '/payment/success',
    render: () => (
      <CheckScope
        scopes={{
          [Resource.UsersUser]: [Actions.Read]
        }}
        redirectIfNotAllowed={routes.login}
      >
        <PaymentSuccess />
      </CheckScope>
    ),
    enabled: true
  },
  profile: {
    label: 'profile',
    url: '/profile',
    render: () => (
      <CheckScope
        scopes={{ [Resource.UsersUser]: [Actions.Read] }}
        redirectIfNotAllowed={routes.login}
      >
        <Profile />
      </CheckScope>
    ),
    enabled: true
  },
  logs: {
    url: '/developers/logs',
    render: () => (
      <CheckScope
        scopes={{
          [Resource.UsersUser]: [Actions.Read],
          [Resource.EventsActivity]: [Actions.Create, Actions.Read]
        }}
        redirectIfNotAllowed={routes.login}
      >
        <Logs />
      </CheckScope>
    ),
    enabled: true
  },
  apikeys: {
    url: '/developers/apikeys',
    render: () => (
      <CheckScope
        scopes={{
          [Resource.UsersUser]: [Actions.Read],
          [Resource.CredentialsApikey]: [
            Actions.Read,
            Actions.Delete,
            Actions.Update,
            Actions.Create
          ]
        }}
        redirectIfNotAllowed={routes.login}
      >
        <Apikeys />
      </CheckScope>
    ),
    enabled: true
  },
  identity: {
    url: '/products/identity',
    render: () => (
      <CheckScope
        scopes={{
          [Resource.UsersUser]: [Actions.Read],
          [Resource.IdentityIssuer]: [Actions.Create, Actions.Read, Actions.Update],
          [Resource.IdentityCredential]: [Actions.Create, Actions.Read],
          [Resource.IdentityRevocation]: [Actions.Create, Actions.Read],
          [Resource.IdentitySchema]: [Actions.Create, Actions.Read, Actions.Delete],
          [Resource.IdentityVerification]: [Actions.Create, Actions.Read, Actions.Delete]
        }}
        redirectIfNotAllowed={routes.login}
      >
        <Identity />
      </CheckScope>
    ),
    enabled: true
  },
  issuerCreate: {
    url: '/products/identity/create',
    render: () => (
      <CheckScope
        scopes={{
          [Resource.UsersUser]: [Actions.Read],
          [Resource.IdentityIssuer]: [Actions.Create, Actions.Read, Actions.Update]
        }}
        redirectIfNotAllowed={routes.login}
      >
        <IssuerCreate />
      </CheckScope>
    ),
    enabled: true
  },
  issuerDetails: {
    url: '/products/identity/issuer/:did',
    render: () => (
      <CheckScope
        scopes={{
          [Resource.UsersUser]: [Actions.Read],
          [Resource.IdentityIssuer]: [Actions.Create, Actions.Read, Actions.Update]
        }}
        redirectIfNotAllowed={routes.login}
      >
        <IssuerDetails />
      </CheckScope>
    ),
    enabled: true
  },
  createSchema: {
    url: '/products/identity/schema/create',
    render: () => (
      <CheckScope
        scopes={{
          [Resource.UsersUser]: [Actions.Read],
          [Resource.IdentitySchema]: [Actions.Create, Actions.Read, Actions.Delete]
        }}
        redirectIfNotAllowed={routes.login}
      >
        <CreateSchema />
      </CheckScope>
    ),
    enabled: true
  },
  SchemaDetails: {
    url: '/products/identity/schema/:cid',
    render: () => (
      <CheckScope
        scopes={{
          [Resource.UsersUser]: [Actions.Read],
          [Resource.IdentitySchema]: [Actions.Create, Actions.Read, Actions.Delete],
          [Resource.IdentityCredential]: [Actions.Create]
        }}
        redirectIfNotAllowed={routes.login}
      >
        <SchemaDetails />
      </CheckScope>
    ),
    enabled: true
  },
  importSchema: {
    url: '/products/identity/import-schema',
    render: () => (
      <CheckScope
        scopes={{
          [Resource.UsersUser]: [Actions.Read],
          [Resource.IdentitySchema]: [Actions.Create, Actions.Read, Actions.Delete]
        }}
        redirectIfNotAllowed={routes.login}
      >
        <ImportSchema />
      </CheckScope>
    ),
    enabled: true
  },
  createQuery: {
    url: '/products/identity/query/create',
    render: () => (
      <CheckScope
        scopes={{
          [Resource.UsersUser]: [Actions.Read],
          [Resource.IdentityIssuer]: [Actions.Create, Actions.Read, Actions.Update]
        }}
        redirectIfNotAllowed={routes.login}
      >
        <CreateQuery />
      </CheckScope>
    ),
    enabled: true
  },

  webhooks: {
    url: '/developers/webhooks',
    render: () => (
      <CheckScope
        scopes={{
          [Resource.UsersUser]: [Actions.Read],
          [Resource.NotificationsWebhook]: [
            Actions.Read,
            Actions.Create,
            Actions.Delete,
            Actions.Update
          ]
        }}
        redirectIfNotAllowed={routes.login}
      >
        <Webhooks />
      </CheckScope>
    ),
    enabled: true
  },
  webhooksNewCreate: {
    url: '/developers/webhooks/create',
    render: () => (
      <CheckScope
        scopes={{
          [Resource.UsersUser]: [Actions.Read],
          [Resource.NotificationsWebhook]: [
            Actions.Read,
            Actions.Create,
            Actions.Delete,
            Actions.Update
          ]
        }}
        redirectIfNotAllowed={routes.login}
      >
        <WebhooksNewCreate />
      </CheckScope>
    ),
    enabled: true
  },
  webhooksDetail: {
    label: 'WebhooksDetails',
    url: '/developers/webhooks/:webhookId',
    render: () => (
      <CheckScope
        scopes={{
          [Resource.UsersUser]: [Actions.Read],
          [Resource.NotificationsWebhook]: [
            Actions.Read,
            Actions.Create,
            Actions.Delete,
            Actions.Update
          ]
        }}
        redirectIfNotAllowed={routes.login}
      >
        <WebhooksDetail />
      </CheckScope>
    ),
    enabled: true
  },
  ethereumApikeysCreate: {
    label: 'ethereumApikeysCreate',
    url: '/developers/apikeys/create-apikey',
    render: () => (
      <CheckScope
        scopes={{
          [Resource.UsersUser]: [Actions.Read],
          [Resource.CredentialsApikey]: [
            Actions.Read,
            Actions.Create,
            Actions.Delete,
            Actions.Update
          ]
        }}
        redirectIfNotAllowed={routes.login}
      >
        <CreateApiKey />
      </CheckScope>
    ),
    enabled: true
  },

  availability: {
    url: '/products/availability',
    render: () => (
      <CheckScope
        scopes={{
          [Resource.UsersUser]: [Actions.Read],
          [Resource.DataAvailabilityHosting]: [Actions.Create, Actions.Read, Actions.Delete],
          [Resource.DataAvailabilityIpfs]: [Actions.Create, Actions.Read, Actions.Delete]
        }}
        redirectIfNotAllowed={routes.login}
      >
        <Availability />
      </CheckScope>
    ),
    enabled: true
  },
  availabilityHostedUploadFile: {
    url: '/products/availability/hosted/upload/file',
    render: () => (
      <CheckScope
        scopes={{
          [Resource.UsersUser]: [Actions.Read],
          [Resource.DataAvailabilityHosting]: [Actions.Create, Actions.Read, Actions.Delete]
        }}
        redirectIfNotAllowed={routes.login}
      >
        <HostedUploadFile />
      </CheckScope>
    ),
    enabled: true
  },
  availabilityIpfsUploadFile: {
    url: '/products/availability/ipfs/upload/file',
    render: () => (
      <CheckScope
        scopes={{
          [Resource.UsersUser]: [Actions.Read],
          [Resource.DataAvailabilityIpfs]: [Actions.Create, Actions.Read, Actions.Delete]
        }}
        redirectIfNotAllowed={routes.login}
      >
        <IpfsUploadFile />
      </CheckScope>
    ),
    enabled: true
  },
  availabilityIpfsUploadDirectory: {
    url: '/products/availability/ipfs/upload/directory',
    render: () => (
      <CheckScope
        scopes={{
          [Resource.UsersUser]: [Actions.Read],
          [Resource.DataAvailabilityIpfs]: [Actions.Create, Actions.Read, Actions.Delete]
        }}
        redirectIfNotAllowed={routes.login}
      >
        <IpfsUploadDirectory />
      </CheckScope>
    ),
    enabled: true
  },
  keys: {
    url: '/products/keys',
    render: () => (
      <CheckScope
        scopes={{
          [Resource.UsersUser]: [Actions.Read],
          [Resource.KeysCertificate]: [
            Actions.Read,
            Actions.Create,
            Actions.Delete,
            Actions.Update
          ],
          [Resource.KeysKey]: [Actions.Read, Actions.Create, Actions.Delete, Actions.Update]
        }}
        redirectIfNotAllowed={routes.login}
      >
        <Keys />
      </CheckScope>
    ),
    enabled: true
  },

  resetPassword: {
    label: 'resetPassword',
    url: '/reset-password',
    // scope: Scope.PUBLIC,
    render: () => (
      <RedirectIfAuthenticated>
        <ResetPassword />
      </RedirectIfAuthenticated>
    ),
    enabled: true
  },
  forgotPassword: {
    label: 'forgotPassword',
    url: '/forgot-password',
    // scope: Scope.PUBLIC,
    render: () => (
      <RedirectIfAuthenticated>
        <ForgotPassword />
      </RedirectIfAuthenticated>
    ),
    enabled: true
  },
  integrity: {
    url: '/products/integrity',
    render: () => (
      <CheckScope
        scopes={{
          [Resource.UsersUser]: [Actions.Read],
          [Resource.CoreAnchor]: [Actions.Read],
          [Resource.CoreMessage]: [Actions.Read],
          [Resource.CoreProof]: [Actions.Read]
        }}
        redirectIfNotAllowed={routes.login}
      >
        <Integrity />
      </CheckScope>
    ),
    enabled: true
  },
  detail: {
    label: 'anchorDetail',
    url: '/products/integrity/anchor/:anchorId',
    render: () => (
      <CheckScope
        scopes={{
          [Resource.UsersUser]: [Actions.Read],
          [Resource.CoreAnchor]: [Actions.Read]
        }}
        redirectIfNotAllowed={routes.login}
      >
        <AnchorDetail />
      </CheckScope>
    ),
    enabled: true
  },
  recordDetail: {
    label: 'recordDetail',
    url: '/products/integrity/record/:recordId',
    render: () => (
      <CheckScope
        scopes={{
          [Resource.UsersUser]: [Actions.Read],
          [Resource.CoreAnchor]: [Actions.Read]
        }}
        redirectIfNotAllowed={routes.login}
      >
        <RecordDetail />
      </CheckScope>
    ),
    enabled: true
  },

  documentation: {
    url: '/developers/documentation',
    render: () => (
      <CheckScope
        scopes={{ [Resource.UsersUser]: [Actions.Read] }}
        redirectIfNotAllowed={routes.login}
      >
        <Documentation />
      </CheckScope>
    ),
    enabled: true
  },

  developers: {
    url: '/developers',
    render: () => (
      <CheckScope
        scopes={{
          [Resource.UsersUser]: [Actions.Read]
        }}
        redirectIfNotAllowed={routes.login}
      ></CheckScope>
    ),
    enabled: true
  },
  signup: {
    label: 'signup',
    url: '/signup',
    render: () => (
      <RedirectIfAuthenticated>
        <Signup />
      </RedirectIfAuthenticated>
    ),
    enabled: true
  },
  verifyAccount: {
    label: 'verify-account',
    url: '/verify-account',
    render: () => (
      <RedirectIfAuthenticated>
        <VerifyAccount />
      </RedirectIfAuthenticated>
    ),
    enabled: true
  },
  configuration: {
    url: '/configuration',
    render: () => (
      <CheckScope
        scopes={{
          [Resource.UsersUser]: [Actions.Read],
          [Resource.SubscriptionsSubscription]: [Actions.Read]
        }}
        redirectIfNotAllowed={routes.login}
      >
        <Configuration />
      </CheckScope>
    ),
    enabled: true
  },

  certifier: {
    label: 'Certifier',
    url: '/certifier',
    render: () => (
      <CheckScope
        scopes={{
          [Resource.UsersUser]: [Actions.Read],
          [Resource.CertifierProcess]: [Actions.Read, Actions.Update, Actions.Create]
        }}
        redirectIfNotAllowed={routes.login}
      >
        <Certifier />
      </CheckScope>
    ),
    enabled: true
  },
  detailCertifier: {
    label: 'certifierDetail',
    url: '/certifier/:processId',
    render: () => (
      <CheckScope
        scopes={{
          [Resource.UsersUser]: [Actions.Read],
          [Resource.CertifierProcess]: [Actions.Read]
        }}
        redirectIfNotAllowed={routes.login}
      >
        <DetailCertifier />
      </CheckScope>
    ),
    enabled: true
  },
  billing: {
    label: 'Billing and plans',
    url: '/billing',
    render: () => (
      <CheckScope
        scopes={{
          [Resource.UsersUser]: [Actions.Read],
          [Resource.SubscriptionsPlan]: [Actions.Read],
          [Resource.SubscriptionsUsage]: [Actions.Read]
        }}
        redirectIfNotAllowed={routes.login}
      >
        <Billing />
      </CheckScope>
    ),
    enabled: true
  },
  invoices: {
    label: 'Invoices',
    url: '/billing/invoices',
    render: () => (
      <CheckScope
        scopes={{
          [Resource.UsersUser]: [Actions.Read],
          [Resource.SubscriptionsInvoice]: [Actions.Read]
        }}
        redirectIfNotAllowed={routes.login}
      >
        <Invoices />
      </CheckScope>
    ),
    enabled: true
  },
  plansAndUsage: {
    label: 'Plans and usage',
    url: '/billing/plans-and-usage',
    render: () => (
      <CheckScope
        scopes={{
          [Resource.UsersUser]: [Actions.Read],
          [Resource.SubscriptionsUsage]: [Actions.Read],
          [Resource.SubscriptionsPlan]: [Actions.Read]
        }}
        redirectIfNotAllowed={routes.login}
      >
        <PlansAndUsage />
      </CheckScope>
    ),
    enabled: true
  },
  updatePlan: {
    label: 'Update plan',
    url: '/billing/plans-and-usage/update-plan',
    render: () => (
      <CheckScope
        scopes={{
          [Resource.UsersUser]: [Actions.Read],
          [Resource.SubscriptionsSubscription]: [Actions.Update, Actions.Read, Actions.Delete]
        }}
        redirectIfNotAllowed={routes.login}
      >
        <UpdatePlan />
      </CheckScope>
    ),
    enabled: true
  },
  products: {
    url: '/products',
    render: () => (
      <CheckScope
        scopes={{
          [Resource.UsersUser]: [Actions.Read]
        }}
        redirectIfNotAllowed={routes.login}
      ></CheckScope>
    ),
    enabled: true
  },
  createKey: {
    label: 'createKey',
    url: '/products/keys/create-key',
    render: () => (
      <CheckScope
        scopes={{
          [Resource.UsersUser]: [Actions.Read],
          [Resource.KeysKey]: [Actions.Create]
        }}
        redirectIfNotAllowed={routes.login}
      >
        <CreateKey />
      </CheckScope>
    ),
    enabled: true
  },
  createCertificate: {
    label: 'createCertificate',
    url: '/products/keys/create-certificate',
    render: () => (
      <CheckScope
        scopes={{
          [Resource.UsersUser]: [Actions.Read],
          [Resource.KeysCertificate]: [Actions.Create]
        }}
        redirectIfNotAllowed={routes.login}
      >
        <CreateCertificate />
      </CheckScope>
    ),
    enabled: true
  },
  importCertificate: {
    label: 'importCertificate',
    url: '/products/keys/import-certificate',
    render: () => (
      <CheckScope
        scopes={{
          [Resource.UsersUser]: [Actions.Read],
          [Resource.KeysCertificate]: [Actions.Create]
        }}
        redirectIfNotAllowed={routes.login}
      >
        <ImportCertificate />
      </CheckScope>
    ),
    enabled: true
  },
  keyDetail: {
    label: 'keyDetail',
    url: '/products/keys/key/:keyId',
    render: () => (
      <CheckScope
        scopes={{
          [Resource.UsersUser]: [Actions.Read],
          [Resource.KeysKey]: [Actions.Read, Actions.Update, Actions.Delete],
          [Resource.KeysAccessControl]: [Actions.Update, Actions.Read, Actions.Delete]
        }}
        redirectIfNotAllowed={routes.login}
      >
        <KeyDetail />
      </CheckScope>
    ),
    enabled: true
  },

  oidcClientCreate: {
    label: 'oidcClientCreate',
    url: '/products/identity/client/create',
    render: () => (
      <CheckScope
        scopes={{
          [Resource.UsersUser]: [Actions.Read]
        }}
        redirectIfNotAllowed={routes.login}
      >
        <CreateOIDCClient />
      </CheckScope>
    ),
    enabled: true
  },
  certificateDetail: {
    label: 'certificateDetail',
    url: '/products/keys/certificate/:certificateId',
    render: () => (
      <CheckScope
        scopes={{
          [Resource.UsersUser]: [Actions.Read],
          [Resource.KeysCertificate]: [Actions.Read, Actions.Update, Actions.Delete],
          [Resource.KeysAccessControl]: [Actions.Update, Actions.Read, Actions.Delete]
        }}
        redirectIfNotAllowed={routes.login}
      >
        <CertificateDetail />
      </CheckScope>
    ),
    enabled: true
  }
};

export function getRouteFromPath(path: string, params?: ParsedUrlQuery): Route | null {
  const result = Object.values(routes).filter((route) => {
    let routeUrl = Object.assign({}, route).url;
    if (params) {
      for (const [param, paramValue] of Object.entries(params)) {
        if (paramValue) {
          routeUrl = routeUrl.replace(`:${param}`, paramValue.toString());
        }
      }
    }

    return routeUrl == path;
  });

  if (result.length > 0) {
    return result[0];
  } else {
    return null;
  }
}
