import { BloockClient } from '@bloock/sdk';
import { Meta } from '../explorer';

export type ClientList = {
  clients: Client[];
  pagination: {
    meta: Meta;
  };
};

export type Client = {
  name: string;
  user_id?: string;
  redirect_uri: string;
  logout_redirect_uri: string;
  image?: string;
  created_at?: string;
};

export type ClientRequest = {
  client_id: string;
  secret: string;
};

export async function requestGetClientList(
  page: number,
  pageSize: number,
  userId: string
): Promise<ClientList> {
  const url = `${process.env.REACT_APP_IDENTITY_OIDC}/${userId}/clients?page=${page}&per_page=${pageSize}`;

  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' }
  });

  return response.json();
}

export async function requestCreateClient(data: Client): Promise<ClientRequest> {
  const host = `${process.env.REACT_APP_IDENTITY_OIDC}/clients`;

  const response = await fetch(host, {
    method: 'POST',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  });

  return response.json();
}

export async function requestQueryVerificationQr(
  query: string,
  token: string,
  env: string,
  apiHost?: string
): Promise<string> {
  const bloockClient = new BloockClient(
    token,
    apiHost ? apiHost : process.env.REACT_APP_IDENTITY_MANAGED_API
  );
  const identityClient = bloockClient.IdentityClient;

  const verification = await identityClient.createVerification(query);

  return verification.verificationRequest;
}
